import { FontFile } from "./types";
import { EMPTY_FONT, PXF_VERSION } from "./utils/consts";
import { useThrottle } from "./utils/useDebounce";
import { useRefState } from "./utils/useRefState";
import { wrapPromise } from "./utils/wrapPromise";
import * as idb from "idb-keyval";
import { applyPatches, castDraft, Draft, Patch, produceWithPatches } from "immer";
import { useCallback, useEffect, useMemo, useRef } from "react";
import { createContext, useContextSelector } from "use-context-selector";
import { v4 as uuid } from "uuid";

type FontMap = { [key: string]: FontFile };
const DEFAULT_FONT_MAP: FontMap = {
  "6860dc49-1ed7-459d-ba6b-f88386fa5f5e": require("./fonts/Sevener-Regular.json"),
  "bde68ba4-8b7a-435f-a014-c6ae8dbf8882": require("./fonts/Sevener-Bold.json"),
};

// ======================================================================
// LOCAL STORAGE (IndexedDB)
// ======================================================================

const SORT_ORDER_KEY = "__SORT_ORDER__";

type StorageData = {
  fonts: FontMap;
  sortOrder: string[];
};

async function fetchFontsFromStorage(): Promise<StorageData> {
  let fonts: FontMap = {};
  let sortOrder: string[] = [];
  for (const [key, value] of await idb.entries()) {
    if (value && value.hasOwnProperty("__pxf")) {
      fonts[key as string] = value as FontFile;
    }
    if (key === SORT_ORDER_KEY) {
      sortOrder = value as string[];
    }
  }
  if (Object.keys(fonts).length === 0) {
    fonts = { ...DEFAULT_FONT_MAP };
    sortOrder = Object.keys(fonts);
  }
  // TODO: do some validation here
  return { fonts, sortOrder };
}

const storageFontsResource = wrapPromise(fetchFontsFromStorage());

// ======================================================================
// REACT CONTEXT
// ======================================================================

type Store = {
  fonts: FontMap;
  sortOrder: string[];
  activeFontId: string;
};

type FontContextType = {
  store: Store;
  getStore: () => Store;
  setStore: (recipe: (draft: Draft<Store>) => Draft<Store>) => void;
  history: {
    commit: () => void;
    undo: () => void;
    redo: () => void;
  };
};

const DEFAULT_STORE = {
  fonts: DEFAULT_FONT_MAP,
  activeFontId: Object.keys(DEFAULT_FONT_MAP)[0],
  sortOrder: Object.keys(DEFAULT_FONT_MAP),
};

const DEFAULT_FONT_CONTEXT: FontContextType = {
  store: DEFAULT_STORE,
  getStore: () => DEFAULT_STORE,
  setStore: () => {},
  history: {
    commit: () => {},
    undo: () => {},
    redo: () => {},
  },
};

const FontContext = createContext<FontContextType>(DEFAULT_FONT_CONTEXT);

type FontContextProviderProps = {
  children: React.ReactNode | React.ReactNode[];
};

export function FontContextProvider({ children }: FontContextProviderProps) {
  const storageFonts = storageFontsResource.read();
  const [store, setStoreRaw, storeRef] = useRefState<Store>({
    ...storageFonts,
    activeFontId: storageFonts.sortOrder[0],
  });
  const { fonts, sortOrder, activeFontId } = store;

  const currentUndoBatch = useRef<Map<string, Patch>>(new Map());

  const setStore = useCallback(
    (recipe: (draft: Draft<Store>) => Draft<Store>) => {
      setStoreRaw((s) => {
        const [newValue, , inversePatches] = produceWithPatches(s, recipe);

        // This might be hella risky but commit() needs super up-to-date access to the latest value
        // even if it hasn't rendered yet.
        storeRef.current = newValue;

        // Store the list of inverse patches to get back to the pre-committed state
        for (const inversePatch of inversePatches) {
          const patchPath = inversePatch.path.join("__");
          if (!currentUndoBatch.current.has(patchPath)) {
            currentUndoBatch.current.set(patchPath, inversePatch);
          }
        }

        return newValue;
      });
    },
    [setStoreRaw, storeRef]
  );

  const getStore = useCallback(() => {
    return storeRef.current;
  }, [storeRef]);

  // TODO: prompt on unload if these promises haven't finished yet
  useEffect(() => {
    idb.setMany(Object.entries(fonts));
    const existingIds = Object.keys(fonts);
    idb.keys().then((keys) => {
      const keysToDelete = keys.filter((key) => {
        return !(key === SORT_ORDER_KEY || existingIds.includes(key as string));
      });
      return idb.delMany(keysToDelete);
    });
  }, [fonts]);

  // Save changes to sort order
  useEffect(() => {
    idb.set(SORT_ORDER_KEY, sortOrder);
  }, [sortOrder]);

  // Sync sort order if it gets busted
  useEffect(() => {
    let newSortOrder = sortOrder;
    // Find IDs that exist in the FontMap but not the sort order and add them
    const usedFontIds = Object.keys(fonts);
    const nonexistentIds = usedFontIds.filter((id) => !sortOrder.includes(id));
    if (nonexistentIds.length > 0) {
      newSortOrder = [...newSortOrder, ...nonexistentIds];
    }

    // Delete IDs that exist in the sort order but not the FontMap
    const extraneousIds = sortOrder.filter((id) => !fonts.hasOwnProperty(id));
    if (extraneousIds.length > 0) {
      newSortOrder = newSortOrder.filter((id) => !extraneousIds.includes(id));
    }

    if (newSortOrder !== sortOrder) {
      setStore((draft) => {
        draft.sortOrder = newSortOrder;
        return draft;
      });
    }
  }, [fonts, setStore, sortOrder]);

  // History
  const undoBatches = useRef<Patch[][]>([]);
  const redoBatches = useRef<Patch[][]>([]);

  const commit = useCallback(() => {
    if (currentUndoBatch.current.size > 0) {
      undoBatches.current.push([...currentUndoBatch.current.values()]);
      redoBatches.current = [];
      currentUndoBatch.current.clear();
    }
  }, []);
  const undo = useCallback(() => {
    if (currentUndoBatch.current.size === 0) {
      // No uncommitted changes, use the top value
      const target = undoBatches.current.pop();
      if (target?.length) {
        setStoreRaw((store) => {
          const [newValue, , inversePatches] = produceWithPatches(store, (draft) => {
            applyPatches(draft, target);
            return draft;
          });
          redoBatches.current.push(inversePatches);
          return newValue;
        });
      }
    } else {
      // There are uncommitted changes, so just apply the current batch
      const target = [...currentUndoBatch.current.values()];
      if (target.length) {
        currentUndoBatch.current.clear();
        setStoreRaw((store) => {
          const [newValue, , inversePatches] = produceWithPatches(store, (draft) => {
            applyPatches(draft, target);
            return draft;
          });
          redoBatches.current.push(inversePatches);
          return newValue;
        });
      }
    }
  }, [setStoreRaw]);
  const redo = useCallback(() => {
    if (currentUndoBatch.current.size > 0) {
      // Current store is dirty, so we shouldn't do anything.
      return;
    }
    // Store is clean, so pop off the redo batch
    const target = redoBatches.current.pop();
    if (target?.length) {
      setStoreRaw((store) => {
        const [newValue, , inversePatches] = produceWithPatches(store, (draft) => {
          applyPatches(draft, target);
          return draft;
        });
        undoBatches.current.push(inversePatches);
        return newValue;
      });
    }
  }, [setStoreRaw]);
  const history = useMemo(() => ({ commit, undo, redo }), [commit, redo, undo]);

  const contextValue: FontContextType = useMemo(() => {
    return {
      store,
      getStore,
      setStore,
      history,
    };
  }, [store, setStore, getStore, history]);

  return <FontContext.Provider value={contextValue}>{children}</FontContext.Provider>;
}

// ======================================================================
// DATA ACCESSORS
// ======================================================================

export function useFont(id: string): FontFile {
  return useContextSelector(FontContext, (c) => {
    return c.store.fonts[id];
  });
}

export function useFontIdList(): string[] {
  return useContextSelector(FontContext, (c) => {
    return c.store.sortOrder;
  });
}

export function useRearrangeFonts(): (id: string, targetIndex: number) => void {
  const setStore = useContextSelector(FontContext, (c) => c.setStore);
  const { commit } = useHistory();
  return useCallback(
    (id, targetIndex) => {
      setStore((store) => {
        const oldIndex = store.sortOrder.indexOf(id);
        const newIndex = Math.min(targetIndex, store.sortOrder.length - 1);
        if (oldIndex !== newIndex) {
          store.sortOrder.splice(oldIndex, 1);
          store.sortOrder.splice(newIndex, 0, id);
        }
        return store;
      });
      commit();
    },
    [commit, setStore]
  );
}

// ----------------------------------------------------------------------

export function useActiveFontId(): string {
  return useContextSelector(FontContext, (c) => c.store.activeFontId);
}

export function useActiveFontIdGetter(): () => string {
  const getStore = useContextSelector(FontContext, (c) => c.getStore);
  return useCallback(() => {
    const store = getStore();
    return store.activeFontId;
  }, [getStore]);
}

export function useSetActiveFontId() {
  const setStore = useContextSelector(FontContext, (c) => c.setStore);
  const { commit } = useHistory();
  return useCallback(
    (id: string) => {
      setStore((draft) => {
        draft.activeFontId = id;
        return draft;
      });
      commit();
    },
    [commit, setStore]
  );
}

// ----------------------------------------------------------------------

/**
 * The main selector used to get data about the currently active font
 */
export function useActiveFontSelector<T>(selector: (font: FontFile) => T): T {
  return useContextSelector(FontContext, (c) => {
    return selector(c.store.fonts[c.store.activeFontId]);
  });
}

/**
 * Useful if I want to write a getter that doesn't need to update with the context
 */
export function useActiveFontGetter(): () => FontFile {
  const getStore = useContextSelector(FontContext, (c) => c.getStore);
  return useCallback(() => {
    const store = getStore();
    return store.fonts[store.activeFontId];
  }, [getStore]);
}

type FontRecipe = (draft: FontFile) => FontFile;

/**
 * The main setter to change something about the active font. Uses immer.
 */
export function useActiveFontSetter(): (recipe: FontRecipe) => void {
  const setStore = useContextSelector(FontContext, (c) => c.setStore);

  return useCallback(
    (recipe: FontRecipe) => {
      setStore((store) => {
        recipe(store.fonts[store.activeFontId]);
        return store;
      });
    },
    [setStore]
  );
}

// ----------------------------------------------------------------------

export function useCreateFont(): (font?: FontFile) => string {
  const setStore = useContextSelector(FontContext, (c) => c.setStore);
  const { commit } = useHistory();
  return useCallback(
    (font: FontFile = EMPTY_FONT) => {
      const id = uuid();
      setStore((store) => {
        store.fonts[id] = castDraft({ ...font, __pxf: PXF_VERSION });
        store.sortOrder.push(id);
        store.activeFontId = id;
        return store;
      });
      commit();
      return id;
    },
    [commit, setStore]
  );
}

export function useDeleteFont(): (id: string) => void {
  const setStore = useContextSelector(FontContext, (c) => c.setStore);
  const { commit } = useHistory();
  return useCallback(
    (id: string) => {
      setStore((store) => {
        delete store.fonts[id];
        const index = store.sortOrder.indexOf(id);
        store.sortOrder.splice(index, 1);
        const newIndex = index >= store.sortOrder.length ? store.sortOrder.length - 1 : index;
        store.activeFontId = store.sortOrder[newIndex];
        return store;
      });
      commit();
    },
    [commit, setStore]
  );
}

// ----------------------------------------------------------------------

export function useHistory() {
  return useContextSelector(FontContext, (c) => c.history);
}

// ----------------------------------------------------------------------

export function useActiveFontMetadata<C extends "meta" | "spacing", T extends keyof FontFile[C]>(
  category: C,
  fieldName: T
): [FontFile[C][T], (newValue: FontFile[C][T]) => void] {
  const setStore = useContextSelector(FontContext, (c) => c.setStore);
  const { commit } = useHistory();
  const [throttledCommit] = useThrottle(commit, 500);
  const value = useActiveFontSelector((f) => f[category][fieldName]);
  const setter = useCallback(
    (newValue: FontFile[C][T]) => {
      setStore((store) => {
        // @ts-ignore
        store.fonts[store.activeFontId][category][fieldName] = newValue;
        return store;
      });
      throttledCommit();
    },
    [category, fieldName, setStore, throttledCommit]
  );
  return [value, setter];
}
