import { useActiveTool } from "./Toolbar";
import { FontSpacing, GlyphData, ReadonlyVec2 } from "./types";
import {
  blank,
  getGlyphBounds,
  getPixel,
  isGlyphEmpty,
  panGlyph,
  setLine,
  setRect,
} from "./utils/font";
import { useHotkey } from "./utils/keyboard";
import { preventDefault } from "./utils/preventDefault";
import { useEventListener } from "./utils/useEventHandler";
import { StyleSheet, css } from "aphrodite/no-important";
import { memo, useCallback, useEffect, useRef, useState } from "react";

function getClickedPixel(
  event: MouseEvent | React.MouseEvent,
  canvas: HTMLElement,
  scale: number
): ReadonlyVec2 {
  const canvasRect = canvas.getBoundingClientRect();
  const x = Math.floor((event.clientX - canvasRect.left) / scale);
  const y = Math.floor((event.clientY - canvasRect.top) / scale);
  return [x, y];
}

enum ChangeMode {
  ERASER,
  BRUSH,
  PAN,
  MARQUEE_ADD,
  MARQUEE_SUBTRACT,
}

function getChangeValue(mode: ChangeMode): boolean | null {
  switch (mode) {
    case ChangeMode.ERASER:
      return false;
    case ChangeMode.BRUSH:
      return true;
    default:
      return null;
  }
}

type GlyphCanvasProps = {
  glyph: GlyphData;
  grid?: boolean;
  onChange?: (glyph: GlyphData) => void;
  onCommit?: () => void;
  ruler?: boolean;
  scale: number;
  spacing: FontSpacing;
};

function GlyphCanvas_({
  glyph,
  grid = false,
  onChange,
  onCommit,
  ruler = false,
  scale,
  spacing,
}: GlyphCanvasProps) {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const prevCoords = useRef<ReadonlyVec2 | null>(null);

  // Use a copy of the glyph to mutate so that we read from a freshly mutated
  // data set before react sends the new prop.
  const localGlyph = useRef<GlyphData>(glyph);
  useEffect(() => {
    localGlyph.current = glyph;
  }, [glyph]);
  const setGlyph = useCallback(
    (newGlyph: GlyphData) => {
      localGlyph.current = newGlyph;
      if (onChange) {
        onChange(newGlyph);
      }
    },
    [localGlyph, onChange]
  );

  const activeTool = useActiveTool();
  const [cursorOverride, setCursorOverride] = useState<string | null>(null);
  const { canvasSize, baseline, capline, descenderline, marginLeft } = spacing;

  const changeMode = useRef<ChangeMode | null>(null);

  const [selection, setSelection] = useState<GlyphData>(blank(canvasSize));
  const [currentMarquee, setCurrentMarquee] = useState<GlyphData | null>(null);

  // +1px to render an extra border on the right and bottom
  const renderSize = scale * canvasSize + 1;

  const maybeChange = useCallback(
    (coords: ReadonlyVec2) => {
      const [x, y] = coords;
      if (onChange && changeMode.current != null && prevCoords.current != null) {
        if (changeMode.current === ChangeMode.PAN) {
          const [prevX, prevY] = prevCoords.current;
          const delta: ReadonlyVec2 = [x - prevX, y - prevY];
          if (delta[0] || delta[1]) {
            const newGlyph = panGlyph(localGlyph.current, delta, canvasSize);
            prevCoords.current = coords;
            setGlyph(newGlyph);
          }
        } else if (changeMode.current === ChangeMode.MARQUEE_ADD && prevCoords.current != null) {
          setCurrentMarquee(setRect(blank(canvasSize), prevCoords.current!, coords, true));
          console.log("marquee from", prevCoords.current, coords);
        } else {
          const newValue = getChangeValue(changeMode.current);
          if (newValue != null) {
            const newGlyph = setLine(localGlyph.current, prevCoords.current, coords, newValue);
            prevCoords.current = coords;
            setGlyph(newGlyph);
          }
        }
      }
    },
    [canvasSize, onChange, setGlyph]
  );

  const handleMouseDown = useCallback(
    (event: MouseEvent | React.MouseEvent) => {
      event.preventDefault();
      if (onChange && canvasRef.current) {
        const coords = getClickedPixel(event, canvasRef.current, scale);
        switch (event.button) {
          case 0: // left click
            if (activeTool === "pencil") {
              changeMode.current = ChangeMode.BRUSH;
            } else if (activeTool === "eraser") {
              changeMode.current = ChangeMode.ERASER;
            } else if (activeTool === "move") {
              changeMode.current = ChangeMode.PAN;
            } else if (activeTool === "marquee") {
              setSelection(blank(canvasSize));
              setCurrentMarquee(blank(canvasSize));
              changeMode.current = ChangeMode.MARQUEE_ADD;
            }
            break;
          case 1: // middle click
            changeMode.current = ChangeMode.PAN;
            setCursorOverride("move");
            break;
          case 2: // right click
            if (activeTool === "pencil" || activeTool === "eraser") {
              changeMode.current = ChangeMode.ERASER;
            }
            setCursorOverride("eraser");
            break;
        }

        prevCoords.current = coords;
        maybeChange(coords);
      }
    },
    [activeTool, canvasSize, maybeChange, onChange, scale]
  );

  const handleMouseUp = useCallback(
    (event: MouseEvent | React.MouseEvent) => {
      if (onCommit && changeMode.current != null) {
        changeMode.current = null;
        prevCoords.current = null;
        if (changeMode.current === ChangeMode.MARQUEE_ADD) {
          setSelection(blank(canvasSize));
          setCurrentMarquee(null);
        }
        onCommit();
        setCursorOverride(null);
      }
    },
    [canvasSize, onCommit]
  );
  useEventListener("mouseup", handleMouseUp);

  const handleMouseMove = useCallback(
    (event: MouseEvent | React.MouseEvent) => {
      if (onChange && changeMode.current != null && canvasRef.current) {
        const coords = getClickedPixel(event, canvasRef.current, scale);
        maybeChange(coords);
      }
    },
    [maybeChange, onChange, scale]
  );
  useEventListener("mousemove", handleMouseMove);

  useHotkey(
    "esc",
    useCallback(() => {
      console.log("esc");
      setSelection(blank(canvasSize));
      setCurrentMarquee(blank(canvasSize));
    }, [canvasSize])
  );

  // Draw call
  useEffect(() => {
    const context = canvasRef.current?.getContext("2d");
    if (!context) {
      return;
    }
    context.imageSmoothingEnabled = false;
    context.clearRect(0, 0, renderSize, renderSize);

    // Draw selection
    context.fillStyle = "#eee";
    for (let y = 0; y < canvasSize; y++) {
      for (let x = 0; x < canvasSize; x++) {
        if (getPixel(selection, x, y) || getPixel(currentMarquee ?? [], x, y)) {
          context.fillRect(x * scale, y * scale, scale, scale);
        }
      }
    }

    // Draw the font
    context.fillStyle = "#000";
    for (let y = 0; y < canvasSize; y++) {
      for (let x = 0; x < canvasSize; x++) {
        if (getPixel(glyph, x, y)) {
          context.fillRect(x * scale, y * scale, scale, scale);
        }
      }
    }

    function drawLine(
      start: [number, number],
      end: [number, number],
      color: string,
      width: number = 1,
      dash: number[] = []
    ) {
      if (!context) {
        return;
      }
      context.strokeStyle = color;
      context.lineWidth = width;
      context.beginPath();
      context.setLineDash(dash);
      context.moveTo(start[0], start[1]);
      context.lineTo(end[0], end[1]);
      context.stroke();
    }

    // Compute extra lines
    const bounds = getGlyphBounds(glyph, canvasSize);
    const advanceWidth = bounds.xMax + 1;

    // Draw grid
    if (grid) {
      for (let y = 0; y < canvasSize + 1; y++) {
        drawLine(
          [0, y * scale + 0.5],
          [canvasSize * scale + 1, y * scale + 0.5],
          "#999",
          1,
          [1, 1]
        );
      }
      for (let x = 0; x < canvasSize + 1; x++) {
        drawLine(
          [x * scale + 0.5, 0],
          [x * scale + 0.5, canvasSize * scale + 1],
          "#999",
          1,
          [1, 1]
        );
      }

      // Draw rulers
      drawLine(
        [0, baseline * scale + 0.5],
        [canvasSize * scale + 1, baseline * scale + 0.5],
        "#f00"
      );
      drawLine([0, capline * scale + 0.5], [canvasSize * scale + 1, capline * scale + 0.5], "#f00");
      drawLine(
        [0, descenderline * scale + 0.5],
        [canvasSize * scale + 1, descenderline * scale + 0.5],
        "#f00"
      );
      drawLine(
        [marginLeft * scale + 0.5, 0],
        [marginLeft * scale + 0.5, canvasSize * scale + 1],
        "#f00"
      );
      drawLine(
        [advanceWidth * scale + 0.5, 0],
        [advanceWidth * scale + 0.5, canvasSize * scale + 1],
        "#f00",
        1,
        [3, 1]
      );
    }
  }, [
    glyph,
    scale,
    baseline,
    capline,
    marginLeft,
    grid,
    renderSize,
    canvasSize,
    descenderline,
    selection,
    currentMarquee,
  ]);

  return (
    <canvas
      ref={canvasRef}
      width={renderSize}
      height={renderSize}
      className={css(styles.root) + ` cursor-${cursorOverride ?? activeTool}`}
      onMouseDown={handleMouseDown}
      onClick={preventDefault}
      onContextMenu={preventDefault}
    />
  );
}
export const GlyphCanvas = memo(GlyphCanvas_);

const styles = StyleSheet.create({
  root: {},
});
