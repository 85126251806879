import { BaseButton } from "./Button";
import {
  useFontIdList,
  useFont,
  useActiveFontId,
  useSetActiveFontId,
  useRearrangeFonts,
} from "./FontContext";
import { PxText } from "./PxText";
import { BODY_REGULAR } from "./UIFonts";
import { getNewYIndex, useDraggable } from "./utils/useDraggable";
import { useEventListener } from "./utils/useEventHandler";
import { StyleSheet, css } from "aphrodite/no-important";
import React, { useState, useCallback, useRef } from "react";

const DRAG_THRESHOLD = 1 / 2;

function _Sidebar() {
  const fontIds = useFontIdList();
  const rearrangeFonts = useRearrangeFonts();
  // TODO: convert these to refs where we can
  const [dragIndex, setDragIndex] = useState<number | null>(null);
  const [lineTop, setLineTop] = useState<number | null>(null);
  const [, setCurrentIndex] = useState<number | null>(null);

  const itemContainerRef = useRef<HTMLDivElement>(null);
  const draggable = useDraggable({
    getRootElement: () => itemContainerRef.current,
    onDrag(event) {
      const newIndex = getNewYIndex(event, DRAG_THRESHOLD);
      if (newIndex === event.index) {
        setCurrentIndex(event.index);
        setLineTop(event.elementPosition.y);
      } else {
        setCurrentIndex(event.index + 1);
        setLineTop(event.elementPosition.y + event.element.clientHeight);
      }
      // console.log("drag", event);
    },
    onDragStart(event) {
      setDragIndex(event.index);
      setLineTop(null);
      setCurrentIndex(null);
      // console.log("dragstart", event);
    },
    onDragEnd(event) {
      const newIndex = getNewYIndex(event, DRAG_THRESHOLD);
      // console.log("dragend", event, newIndex);
      if (dragIndex != null && newIndex != null) {
        if (newIndex <= dragIndex) {
          rearrangeFonts(fontIds[dragIndex], newIndex);
        } else {
          rearrangeFonts(fontIds[dragIndex], newIndex - 1);
        }
        if (newIndex === event.index) {
          setCurrentIndex(event.index);
          setLineTop(event.elementPosition.y);
        } else {
          setCurrentIndex(event.index + 1);
          setLineTop(event.elementPosition.y + event.element.clientHeight);
        }
      }

      setDragIndex(null);
      setLineTop(null);
      setCurrentIndex(null);
    },
  });

  return (
    <div className={css(sidebarStyles.root)}>
      <div className={css(sidebarStyles.itemContainer)} ref={itemContainerRef}>
        {fontIds.map((id, index) => {
          return (
            <SidebarItem
              key={id}
              id={id}
              onMouseDown={draggable.getOnMouseDown(index)}
              onMouseMove={draggable.getOnMouseMove(index)}
            />
          );
        })}
      </div>
      {/* {isDragging && <div className={css(sidebarStyles.blocker)} />} */}
      {lineTop != null && (
        <div style={{ top: lineTop }} className={css(sidebarStyles.dragTargetLine)} />
      )}
    </div>
  );
}
export const Sidebar = React.memo(_Sidebar);

type SidebarItemProps = {
  id: string;
  onMouseDown?: React.MouseEventHandler;
  onMouseMove?: React.MouseEventHandler;
};

function SidebarItem({ id, onMouseDown, onMouseMove }: SidebarItemProps) {
  const font = useFont(id);
  const activeFontId = useActiveFontId();
  const setActiveFontId = useSetActiveFontId();

  const isActive = activeFontId === id;
  const [isHovering, setIsHovering] = useState(false);
  const [isDragging, setIsDragging] = useState(false);

  const handleClick = useCallback(() => {
    setActiveFontId(id);
  }, [id, setActiveFontId]);

  const handleMouseOver = useCallback(() => {
    setIsHovering(true);
  }, []);
  const handleMouseOut = useCallback(() => {
    setIsHovering(false);
  }, []);

  const handleMouseDown = useCallback(
    (event: React.MouseEvent) => {
      setIsDragging(true);
      onMouseDown?.(event);
    },
    [onMouseDown]
  );

  useEventListener(
    "mouseup",
    useCallback(() => {
      setIsDragging(false);
    }, [])
  );

  const fontColor = isActive ? "white" : undefined;
  return (
    <div
      className={css(sidebarStyles.itemOuter)}
      onMouseOut={handleMouseOut}
      onMouseOver={handleMouseOver}
      onMouseDown={handleMouseDown}
      onMouseMove={onMouseMove}
    >
      <BaseButton
        onClick={handleClick}
        color={isActive ? "red" : isHovering || isDragging ? "#eee" : "white"}
      >
        <div className={css(sidebarStyles.itemInner)}>
          <div>
            <PxText font={BODY_REGULAR} color={fontColor}>
              {font.meta.name + " / "}
            </PxText>
            <PxText font={BODY_REGULAR} color={fontColor}>
              {font.meta.style}
            </PxText>
          </div>
          <div className={css(sidebarStyles.preview)}>
            <PxText font={font} color={fontColor}>
              The quick brown fox jumps over a lazy dog
            </PxText>
          </div>
        </div>
      </BaseButton>
    </div>
  );
}

const SIDEBAR_WIDTH = 256 + 1;

const sidebarStyles = StyleSheet.create({
  root: {
    display: "flex",
    flexDirection: "column",
    width: SIDEBAR_WIDTH,
    paddingTop: 8,
    borderRight: "1px solid #ddd",
    boxSizing: "content-box",
    position: "relative",
  },
  itemContainer: {
    display: "flex",
    flexDirection: "column",
    position: "relative",
  },
  dragTargetLine: {
    position: "absolute",
    left: 0,
    right: 0,
    height: 2,
    marginTop: 7,
    backgroundColor: "black",
    zIndex: 200,
  },
  blocker: {
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    zIndex: 100,
  },
  itemOuter: {
    marginBottom: 0,
  },
  itemInner: {
    padding: "4px 8px",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    flex: 1,
    width: SIDEBAR_WIDTH,
    // height: LAYER_HEIGHT,
    overflow: "hidden",
    justifyContent: "center",
  },
  preview: {
    marginLeft: 8,
  },
});
