import { useEventListener } from "./useEventHandler";
import { useCallback } from "react";

export function isApple(): boolean {
  return /(Mac|iPhone|iPod|iPad)/i.test(navigator.platform);
}

export function isModKeyDown(event: KeyboardEvent | React.KeyboardEvent): boolean {
  if (isApple()) {
    return event.metaKey;
  } else {
    return event.ctrlKey;
  }
}

const KEY_CODE_MAPPING: { [key: string]: string | undefined } = {
  "/": "Slash",
  esc: "Escape",
};

const MODIFIER_SYMBOL_UNICODE = {
  ctrl: String.fromCharCode(8963),
  cmd: String.fromCharCode(8984),
  alt: String.fromCharCode(8997),
  shift: String.fromCharCode(8679),
} as const;

export function hotkeyDisplay(hotkey: string): string {
  let ret = (hotkey ?? "")
    .toUpperCase()
    .split(",")[0]
    .replace("MOD", isApple() ? "CMD" : "CTRL");

  for (const [name, char] of Object.entries(MODIFIER_SYMBOL_UNICODE)) {
    ret = ret.replace(new RegExp(`\\s*${name}\\s*`, "i"), char);
  }

  return ret;
}

function isHotkeyPressed(event: KeyboardEvent | React.KeyboardEvent, hotkey: string): boolean {
  const split = hotkey.split(" ");
  let mods = {
    alt: false,
    shift: false,
    mod: false,
  };

  for (const part of split) {
    const p = part.toLowerCase().trim();
    if (p === "alt") {
      mods.alt = true;
    } else if (p === "shift") {
      mods.shift = true;
    } else if (p === "mod") {
      mods.mod = true;
    } else if (p.match(/^[0-9]$/)) {
      if (event.code !== `Digit${p}`) return false;
    } else if (p.match(/^[A-Za-z]$/)) {
      if (event.code !== `Key${p.toUpperCase()}`) return false;
    } else if (KEY_CODE_MAPPING[p]) {
      if (event.code !== KEY_CODE_MAPPING[p]) return false;
    } else {
      throw new Error(`invalid hotkey "${hotkey}`);
    }
  }

  if (
    mods.alt === event.altKey &&
    mods.shift === event.shiftKey &&
    mods.mod === isModKeyDown(event)
  ) {
    return true;
  }
  return false;
}

export function useHotkey(
  hotkey: string | undefined | null,
  handler: (event: KeyboardEvent) => void
) {
  useEventListener(
    "keydown",
    useCallback(
      (event: KeyboardEvent) => {
        if (!hotkey || !(event.target instanceof Element)) {
          return;
        }

        const isInputElement = ["input", "textarea"].includes(event.target.tagName.toLowerCase());
        const isInputHotkeyed =
          event.target.getAttribute("data-use-hotkeys") && isModKeyDown(event);
        if (isInputElement && !isInputHotkeyed) {
          return;
        }

        const hasPressedHotkey = hotkey
          .split(",")
          .map((hotkey) => isHotkeyPressed(event, hotkey.trim()))
          .some((x) => x === true);
        if (hasPressedHotkey) {
          handler(event);
        }
      },
      [handler, hotkey]
    )
  );
}
