import React, { useEffect, useMemo, useRef } from "react";

const DRAG_MINIMUM_DISTANCE = 4;

type DragEvent = {
  id?: string;
  index: number;
  element: Element;
  elementPosition: { x: number; y: number };
  mousePosition: { x: number; y: number };
};
type UseDraggableOptions<TRootElement extends Element> = {
  getRootElement: () => TRootElement | null;
  onDrag: (event: DragEvent) => void;
  onDragStart: (event: DragEvent) => void;
  onDragEnd: (event: DragEvent) => void;

  /** Pass an ID in to allow elements to be dragged across instances of `useDraggable` */
  id?: string;
};
export type Draggable = {
  getOnMouseMove: (index: number) => React.MouseEventHandler;
  getOnMouseDown: (index: number) => React.MouseEventHandler;
};

function getDragEvent<TRootElement extends Element>(
  index: number,
  rootElement: TRootElement,
  event: React.MouseEvent | MouseEvent,
  id?: string
): DragEvent {
  if (event.target instanceof HTMLElement) {
    const targetRect = event.target.getBoundingClientRect();
    const rootRect = rootElement.getBoundingClientRect();
    return {
      index,
      id,
      element: event.target,
      elementPosition: {
        x: targetRect.x - rootRect.x,
        y: targetRect.y - rootRect.y,
      },
      mousePosition: {
        x: event.clientX - targetRect.x,
        y: event.clientY - targetRect.y,
      },
    };
  }
  throw new Error("invalid event target");
}

export function useDraggable<TRootElement extends Element>({
  id,
  getRootElement,
  onDrag,
  onDragEnd,
  onDragStart,
}: UseDraggableOptions<TRootElement>): Draggable {
  const activeIndexRef = useRef<number | null>(null);
  const lastHoveredIndexRef = useRef<number | null>(null);
  const initCoordsRef = useRef<{ x: number; y: number } | null>(null);
  const didSendStartRef = useRef<boolean>(false);

  // Mouseup doesn't necessarily happen on the element itself, so it's attached outside
  useEffect(() => {
    function handleMouseUp(event: MouseEvent) {
      const index = lastHoveredIndexRef.current;
      const rootElement = getRootElement();
      if (index != null && rootElement != null) {
        onDragEnd(getDragEvent(index, rootElement, event, id));
      }
      activeIndexRef.current = null;
      lastHoveredIndexRef.current = null;
      initCoordsRef.current = null;
      didSendStartRef.current = false;
    }
    document.addEventListener("mouseup", handleMouseUp);
    return () => {
      document.removeEventListener("mouseup", handleMouseUp);
    };
  }, [getRootElement, id, onDragEnd]);

  // Global mousedown for cross-instance functionality
  const isGlobalMouseDown = useRef<boolean>(false);
  useEffect(() => {
    function handleMouseDown() {
      if (id != null) {
        isGlobalMouseDown.current = true;
      }
    }
    function handleMouseUp() {
      isGlobalMouseDown.current = false;
    }
    document.addEventListener("mousedown", handleMouseDown);
    document.addEventListener("mouseup", handleMouseUp);
    return () => {
      document.removeEventListener("mousedown", handleMouseDown);
      document.removeEventListener("mouseup", handleMouseUp);
    };
  }, [id]);

  return useMemo(() => {
    // TODO: cache/stabilize handlers
    return {
      getOnMouseMove(index: number) {
        return (event: React.MouseEvent) => {
          const rootElement = getRootElement();
          const initCoords = initCoordsRef.current;

          // Local mouse moves
          if (activeIndexRef.current != null && rootElement != null && initCoords != null) {
            lastHoveredIndexRef.current = index;
            if (didSendStartRef.current === true) {
              onDrag(getDragEvent(index, rootElement, event, id));
            } else if (
              Math.abs(initCoords.x - event.clientX) >= DRAG_MINIMUM_DISTANCE ||
              Math.abs(initCoords.y - event.clientY) >= DRAG_MINIMUM_DISTANCE
            ) {
              onDragStart(getDragEvent(index, rootElement, event, id));
              didSendStartRef.current = true;
            }
          }

          // Remote mouse moves
          else if (
            id != null &&
            rootElement != null &&
            initCoords == null &&
            isGlobalMouseDown.current === true
          ) {
            lastHoveredIndexRef.current = index;
            onDrag(getDragEvent(index, rootElement, event, id));
          }
        };
      },
      getOnMouseDown(index: number) {
        return (event: React.MouseEvent) => {
          const rootElement = getRootElement();
          if (rootElement != null) {
            activeIndexRef.current = index;
            initCoordsRef.current = { x: event.clientX, y: event.clientY };
          }
        };
      },
    };
  }, [getRootElement, id, onDrag, onDragStart]);
}

export function getNewYIndex(event: DragEvent, threshold: number): number | null {
  const yPct = event.mousePosition.y / event.element.clientHeight;
  if (yPct < threshold) {
    return event.index;
  } else if (yPct >= 1 - threshold) {
    return event.index + 1;
  }
  return null;
}
