import { Button, BaseButton } from "./Button";
import { useActiveFontSelector, useActiveFontSetter } from "./FontContext";
import { GlyphGallery } from "./GlyphGallery";
import { Icon } from "./Icon";
import { PxText } from "./PxText";
import { BODY_REGULAR } from "./UIFonts";
import { CharCode, KerningPair } from "./types";
import { StyleSheet, css } from "aphrodite/no-important";
import { useCallback, useState, useEffect } from "react";

type KerningPairsType = {
  currentCharCode: CharCode;
};

export function KerningPairs({ currentCharCode }: KerningPairsType) {
  // TODO: only show relevant kerning pairs
  // const pairs = useKerningPairsForGlyph(currentCharCode);
  const pairs = useActiveFontSelector((f) => f.kerning);
  const set = useActiveFontSetter();

  const handleNewPair = useCallback(() => {
    set((draft) => {
      draft.kerning.push({ left: "", right: "", value: 0 });
      return draft;
    });
  }, [set]);

  const handleChange = useCallback(
    (index: number, pair: KerningPair | null) => {
      set((draft) => {
        if (pair == null) {
          draft.kerning.splice(index, 1);
        } else {
          draft.kerning[index] = pair;
        }
        return draft;
      });
    },
    [set]
  );

  return (
    <div style={{ marginTop: 8, marginBottom: 80 }}>
      <Button onClick={handleNewPair}>
        <PxText font={BODY_REGULAR} color="white">
          NEW KERNING PAIR
        </PxText>
      </Button>
      {pairs.map((pair, index) => {
        return (
          <KerningPairRow
            currentCharCode={currentCharCode}
            pair={pair}
            index={index}
            onChange={handleChange}
            key={index}
          />
        );
      })}
    </div>
  );
}

type KerningPairProps = {
  currentCharCode: CharCode;
  pair: KerningPair;
  index: number;
  onChange: (index: number, pair: KerningPair | null) => void;
};

function KerningPairRow({ currentCharCode, pair, index, onChange }: KerningPairProps) {
  const [openFor, setOpenFor] = useState<null | "left" | "right">(null);
  const [inputValue, setInputValue] = useState<string>(String(pair.value));

  useEffect(() => {
    setInputValue(String(pair.value));
  }, [pair.value]);

  const handleGlyphClick = useCallback(
    (charCode: CharCode) => {
      if (openFor) {
        setOpenFor(null);
        onChange(index, { ...pair, [openFor]: charCode });
      }
    },
    [index, onChange, openFor, pair]
  );

  const handleDelete = useCallback(() => {
    onChange(index, null);
  }, [index, onChange]);

  const handleInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setInputValue(event.target.value);
      const asNumber = Number(event.target.value);
      if (event.target.value !== "" && isFinite(asNumber)) {
        onChange(index, { ...pair, value: asNumber });
      }
    },
    [index, onChange, pair]
  );

  return (
    <>
      <div style={{ padding: 8, display: "flex", gap: 4, alignItems: "center" }}>
        <BaseButton onClick={() => setOpenFor("left")} color="red">
          <div className={css(styles.button)}>
            <PxText font={BODY_REGULAR} color="white">
              {pair.left !== "" ? String.fromCharCode(+pair.left) : "N/A"}
            </PxText>
          </div>
        </BaseButton>
        <div
          className={css(styles.text)}
          style={{
            width: useActiveFontSelector((f) => f.spacing.canvasSize) * 2,
          }}
        >
          <PxText font={useActiveFontSelector((f) => f)}>
            {String.fromCharCode(+pair.left) + String.fromCharCode(+pair.right)}
          </PxText>
        </div>
        <BaseButton onClick={() => setOpenFor("right")} color="red">
          <div className={css(styles.button)}>
            <PxText font={BODY_REGULAR} color="white">
              {pair.right !== "" ? String.fromCharCode(+pair.right) : "N/A"}
            </PxText>
          </div>
        </BaseButton>
        <input value={inputValue} onChange={handleInputChange} />
        <BaseButton onClick={handleDelete} color="red">
          <div className={css(styles.button)}>
            <Icon name="trash" color="white" />
          </div>
        </BaseButton>
      </div>

      {openFor && (
        <>
          pick a glyph
          <GlyphGallery onGlyphClick={handleGlyphClick} />
        </>
      )}
    </>
  );
}

const styles = StyleSheet.create({
  button: {
    width: 24,
    height: 24,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  text: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
});
