import { useEffect } from "react";

export function useEventListener<K extends keyof WindowEventMap>(
  eventName: K,
  handler: (event: WindowEventMap[K]) => void
): void;
export function useEventListener<
  K extends keyof HTMLElementEventMap,
  T extends HTMLElement = HTMLDivElement
>(eventName: K, handler: (event: HTMLElementEventMap[K]) => void, element: T): void;

export function useEventListener<
  KW extends keyof WindowEventMap,
  KH extends keyof HTMLElementEventMap,
  T extends HTMLElement | void = void
>(
  eventName: KW | KH,
  handler: (event: WindowEventMap[KW] | HTMLElementEventMap[KH] | Event) => void,
  element?: T
) {
  useEffect(() => {
    const targetElement: T | Window = element || window;
    if (!(targetElement && targetElement.addEventListener)) {
      return;
    }

    targetElement.addEventListener(eventName, handler);
    return () => {
      targetElement.removeEventListener(eventName, handler);
    };
  }, [eventName, element, handler]);
}
