import { useEffect, useRef, useState } from "react";

export function useRefState<T>(
  initialState: T | (() => T)
): [T, React.Dispatch<React.SetStateAction<T>>, React.MutableRefObject<T>] {
  const [value, setValue] = useState(initialState);
  const ref = useRef<T>(value);
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return [value, setValue, ref];
}
