import { Button } from "./Button";
import {
  useActiveFontMetadata,
  useActiveFontSelector,
  useActiveFontSetter,
  useHistory,
} from "./FontContext";
import { GlyphCanvas } from "./GlyphCanvas";
import { GlyphGallery } from "./GlyphGallery";
import { KerningPairs } from "./KerningPairs";
import { PxText } from "./PxText";
import { Sidebar } from "./Sidebar";
import { Toolbar } from "./Toolbar";
import { SIXER_REGULAR } from "./UIFonts";
import { CharCode, GlyphData } from "./types";
import { preloadDownloaders } from "./utils/font";
import { useHotkey } from "./utils/keyboard";
import { StyleSheet, css } from "aphrodite/no-important";
import { ComponentProps, useCallback, useEffect, useState } from "react";

function MetadataEditor() {
  const [name, setName] = useActiveFontMetadata("meta", "name");
  const [style, setStyle] = useActiveFontMetadata("meta", "style");
  const [author, setAuthor] = useActiveFontMetadata("meta", "author");

  // TODO: move these elsewhere, like the canvas
  const [letterSpacing, setLetterSpacing] = useActiveFontMetadata("spacing", "letterSpacing");
  const [spaceSize, setSpaceSize] = useActiveFontMetadata("spacing", "spaceSize");
  const [capline, setCapline] = useActiveFontMetadata("spacing", "capline");
  const [baseline, setBaseline] = useActiveFontMetadata("spacing", "baseline");
  const [descenderline, setDescenderline] = useActiveFontMetadata("spacing", "descenderline");
  const [marginLeft, setMarginLeft] = useActiveFontMetadata("spacing", "marginLeft");
  const [canvasSize, setCanvasSize] = useActiveFontMetadata("spacing", "canvasSize");

  return (
    <div>
      <div>
        <label>
          name
          <input
            data-use-hotkeys={true}
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </label>
        <label>
          style
          <input
            data-use-hotkeys={true}
            type="text"
            value={style}
            onChange={(e) => setStyle(e.target.value)}
          />
        </label>
        <label>
          author
          <input
            data-use-hotkeys={true}
            type="text"
            value={author}
            onChange={(e) => setAuthor(e.target.value)}
          />
        </label>
      </div>
      <div>
        <label>
          letterSpacing
          <input
            data-use-hotkeys={true}
            type="number"
            value={letterSpacing}
            onChange={(e) => setLetterSpacing(Number(e.target.value))}
          />
        </label>
        <label>
          spaceSize
          <input
            data-use-hotkeys={true}
            type="number"
            value={spaceSize}
            onChange={(e) => setSpaceSize(Number(e.target.value))}
          />
        </label>
        <label>
          capline
          <input
            data-use-hotkeys={true}
            type="number"
            value={capline}
            onChange={(e) => setCapline(Number(e.target.value))}
          />
        </label>
        <label>
          baseline
          <input
            data-use-hotkeys={true}
            type="number"
            value={baseline}
            onChange={(e) => setBaseline(Number(e.target.value))}
          />
        </label>
        <label>
          descenderline
          <input
            data-use-hotkeys={true}
            type="number"
            value={descenderline}
            onChange={(e) => setDescenderline(Number(e.target.value))}
          />
        </label>
        <label>
          marginLeft
          <input
            data-use-hotkeys={true}
            type="number"
            value={marginLeft}
            onChange={(e) => setMarginLeft(Number(e.target.value))}
          />
        </label>
        <label>
          canvasSize
          <input
            data-use-hotkeys={true}
            type="number"
            value={canvasSize}
            onChange={(e) => setCanvasSize(Number(e.target.value))}
          />
        </label>
      </div>
    </div>
  );
}

function App() {
  const setFont = useActiveFontSetter();

  const [currentCharCode, setCurrentCharCode] = useState<CharCode>("65");

  useEffect(() => {
    preloadDownloaders();
  }, []);

  const handleGlyphChange = useCallback(
    (data: GlyphData) => {
      setFont((draft) => {
        draft.glyphs[currentCharCode] = data;
        return draft;
      });
    },
    [currentCharCode, setFont]
  );

  const history = useHistory();
  useHotkey(
    "mod z",
    useCallback(
      (event) => {
        event.preventDefault();
        history.undo();
      },
      [history]
    )
  );
  useHotkey(
    "mod y, mod shift z",
    useCallback(
      (event) => {
        event.preventDefault();
        history.redo();
      },
      [history]
    )
  );

  const handleCurrentCharCodeChange = useCallback(
    (charCode: CharCode) => {
      history.commit();
      setCurrentCharCode(charCode);
    },
    [history]
  );

  const handleNewGlyph = useCallback(() => {
    const charCodeString = prompt("Character code (decimal)?");
    const charCode = Number(charCodeString);
    if (isNaN(charCode)) {
      alert(`Invalid character code "${charCodeString}`);
    }
    setFont((draft) => {
      draft.glyphs[charCode] = [];
      return draft;
    });
  }, [setFont]);

  return (
    <div className="App">
      <Toolbar />
      <div className={css(styles.main)}>
        <Sidebar />
        <div style={{ paddingTop: 8 }}>
          <div>
            <div style={{ margin: 8 }}>
              <Editor
                key={currentCharCode}
                currentCharCode={currentCharCode}
                onChange={handleGlyphChange}
                onCommit={history.commit}
              />
            </div>
            <MetadataEditor />
          </div>
          <div style={{ marginTop: 16 }}>
            <Preview>The quick, brown fox jumps over a lazy dog.</Preview>
          </div>
          <div style={{ marginTop: 16 }}>
            <GlyphGallery
              onGlyphClick={handleCurrentCharCodeChange}
              currentCharCode={currentCharCode}
            />
            <Button color="red" onClick={handleNewGlyph}>
              <PxText color="white" font={SIXER_REGULAR}>
                New Glyph
              </PxText>
            </Button>
          </div>
          <KerningPairs currentCharCode={currentCharCode} />
        </div>
      </div>
    </div>
  );
}

type EditorProps = {
  currentCharCode: CharCode;
} & Pick<ComponentProps<typeof GlyphCanvas>, "onChange" | "onCommit">;

function Editor({ currentCharCode, onChange, onCommit }: EditorProps) {
  const currentGlyph = useActiveFontSelector((f) => f.glyphs[currentCharCode]);
  const spacing = useActiveFontSelector((f) => f.spacing);
  return (
    <GlyphCanvas
      glyph={currentGlyph ?? []}
      grid={true}
      onChange={onChange}
      onCommit={onCommit}
      scale={32}
      spacing={spacing}
    />
  );
}

type PreviewProps = {
  children: string;
  scale?: number;
};

function Preview({ children, scale }: PreviewProps) {
  const font = useActiveFontSelector((f) => f);
  const [value, setValue] = useState<string>(children);
  return (
    <>
      <div>
        <input
          type="text"
          value={value}
          onChange={(e) => setValue(e.target.value)}
          data-native-kb-events={true}
        />
      </div>
      <div style={{ margin: 8 }}>
        <PxText font={font} scale={scale}>
          {value}
        </PxText>
      </div>
    </>
  );
}

export default App;

const styles = StyleSheet.create({
  main: {
    display: "flex",
    flexDirection: "row",
  },
});
