import { BaseButton } from "./Button";
import {
  useActiveFontGetter,
  useActiveFontIdGetter,
  useCreateFont,
  useDeleteFont,
} from "./FontContext";
import { Icon, IconName } from "./Icon";
import { PxText } from "./PxText";
import { Tooltip } from "./Tooltip";
import { BODY_REGULAR } from "./UIFonts";
import { EMPTY_FONT } from "./utils/consts";
import { convertFromBitFontMaker2, downloadJSON, downloadOTF } from "./utils/font";
import { hotkeyDisplay, useHotkey } from "./utils/keyboard";
import { css, StyleSheet } from "aphrodite/no-important";
import merge from "lodash/merge";
import React, { ReactNode, useCallback, useMemo, useRef, useState } from "react";
import { createContext, useContextSelector } from "use-context-selector";

const TOOL_NAMES = {
  pencil: "Pencil",
  eraser: "Eraser",
  move: "Move",
  marquee: "Marquee",
} as const;

type ToolName = keyof typeof TOOL_NAMES;
type ToolbarContextType = {
  tool: ToolName;
  setTool: (tool: ToolName) => void;
};

const ToolbarContext = createContext<ToolbarContextType>({
  tool: "pencil",
  setTool: () => {},
});

export function ToolbarContextProvider({ children }: { children: ReactNode }) {
  const [tool, setTool] = useState<ToolName>("pencil");
  const contextValue = useMemo(() => {
    return { tool, setTool };
  }, [tool]);
  return <ToolbarContext.Provider value={contextValue}>{children}</ToolbarContext.Provider>;
}

export function useActiveTool(): ToolName {
  return useContextSelector(ToolbarContext, (ctx) => ctx.tool);
}

export function useSetActiveTool(): (tool: ToolName) => void {
  return useContextSelector(ToolbarContext, (ctx) => ctx.setTool);
}

export function Toolbar() {
  const getActiveFontId = useActiveFontIdGetter(); // TODO: possible to move this to a getter?
  const getFontFile = useActiveFontGetter();
  const createFont = useCreateFont();
  const deleteFont = useDeleteFont();

  const handleNew = useCallback(
    (event: ToolbarEventType) => {
      event.preventDefault();
      createFont();
    },
    [createFont]
  );

  const handleSave = useCallback(
    (event: ToolbarEventType) => {
      event.preventDefault();
      const font = getFontFile();
      downloadJSON(font);
    },
    [getFontFile]
  );

  const handleClone = useCallback(
    (event: ToolbarEventType) => {
      event.preventDefault();
      createFont(getFontFile());
    },
    [createFont, getFontFile]
  );

  const handleDelete = useCallback(
    (event: ToolbarEventType) => {
      event.preventDefault();
      deleteFont(getActiveFontId());
    },
    [deleteFont, getActiveFontId]
  );

  const handleOpen = useCallback(
    (event: ToolbarEventType) => {
      event.preventDefault();
      const inputEl = document.createElement("input");
      inputEl.setAttribute("type", "file");
      inputEl.addEventListener("change", (e: Event) => {
        const file = inputEl.files?.[0];
        if (file) {
          file.text().then((text) => {
            const obj = JSON.parse(text);
            if (obj.hasOwnProperty("__pxf")) {
              delete obj.__pxf;
              createFont(merge({}, EMPTY_FONT, obj));
            } else {
              createFont(convertFromBitFontMaker2(obj));
            }
          });
        }
      });
      inputEl.click();
    },
    [createFont]
  );

  const handleDownloadOTF = useCallback(
    (event: ToolbarEventType) => {
      event.preventDefault();
      const font = getFontFile();
      downloadOTF(font);
    },
    [getFontFile]
  );

  return (
    <div className={css(styles.root)}>
      <div className={css(styles.inner)}>
        {/* <div className={css(styles.logo)}>
          <Icon name="dog" color="white" flipX={true} />
          <PxText font={BODY_BOLD} color="white">
            PXF
          </PxText>
        </div> */}
        <ToolbarButton icon="file" hotkey="mod n" text="New Font" onClick={handleNew} />
        <ToolbarButton icon="open" hotkey="mod o" text="Open from JSON" onClick={handleOpen} />
        <ToolbarButton icon="save" hotkey="mod s" text="Export to JSON" onClick={handleSave} />
        <ToolbarButton
          icon="download"
          hotkey="shift mod s"
          text="Export to OTF"
          onClick={handleDownloadOTF}
        />
        <div className={css(styles.divider)} />
        <ToolbarButton
          icon="copy"
          // hotkey="mod s"
          text="Clone Font"
          onClick={handleClone}
        />
        <ToolbarButton
          icon="trash"
          // hotkey="mod s"
          text="Delete Font"
          onClick={handleDelete}
        />
        <div className={css(styles.divider)} />
        <div className={css(styles.spacer)} />
        <ToolbarButton icon="pencil" tool="pencil" hotkey="b,p" text="Pencil" />
        <ToolbarButton icon="eraser" tool="eraser" hotkey="e" text="Eraser" />
        <ToolbarButton icon="move" tool="move" hotkey="v" text="Move" />
        <ToolbarButton icon="marquee" tool="marquee" hotkey="m" text="Marquee" />
      </div>
    </div>
  );
}

type ToolbarEventType =
  | React.MouseEvent<HTMLButtonElement>
  | React.KeyboardEvent<HTMLButtonElement>
  | MouseEvent
  | KeyboardEvent;

type ToolbarButtonProps = {
  icon: IconName;
  tool?: ToolName;
  onClick?: (event: ToolbarEventType) => void;
  hotkey?: string;
  text?: string;
  color?: string;
};

function ToolbarButton({ icon, tool, hotkey, onClick, text, color }: ToolbarButtonProps) {
  const isActive = useActiveTool() === tool;
  const setActiveTool = useSetActiveTool();
  const buttonRef = useRef<HTMLButtonElement>(null);

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      if (tool) {
        setActiveTool(tool);
      }
      if (onClick) {
        onClick(event);
      }
    },
    [onClick, setActiveTool, tool]
  );

  useHotkey(
    hotkey,
    useCallback(
      (event: KeyboardEvent) => {
        if (tool) {
          setActiveTool(tool);
        }
        if (onClick) {
          onClick(event);
        }
      },
      [onClick, setActiveTool, tool]
    )
  );

  return (
    <>
      <BaseButton
        ref={buttonRef}
        onClick={handleClick}
        color={color ? color : isActive ? "red" : "black"}
        hoverVector={[0, 3]}
      >
        <div className={css(styles.button)}>
          <Icon name={icon} color="white" />
        </div>
      </BaseButton>
      <Tooltip anchor={buttonRef}>
        {text && (
          <PxText font={BODY_REGULAR} color="white">
            {text}
          </PxText>
        )}
        {hotkey && (
          <PxText font={BODY_REGULAR} color="#aaa">
            {hotkeyDisplay(hotkey)}
          </PxText>
        )}
      </Tooltip>
    </>
  );
}

const TOOLBAR_SIZE = 40;

const styles = StyleSheet.create({
  root: {
    height: TOOLBAR_SIZE,
  },
  inner: {
    backgroundColor: "black",
    height: TOOLBAR_SIZE,
    display: "flex",
    alignItems: "center",
    padding: "0 4px",
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    zIndex: 20000,
  },
  logo: {
    display: "flex",
    alignItems: "center",
    gap: 6,
    marginRight: 8,
  },
  button: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: TOOLBAR_SIZE,
    height: TOOLBAR_SIZE,
  },
  divider: {
    backgroundColor: "#222",
    margin: "0 4px",
    width: 1,
    height: TOOLBAR_SIZE,
  },
  spacer: {
    flexGrow: 1,
  },
});
