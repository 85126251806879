import { css, StyleSheet } from "aphrodite/no-important";
import { ReactNode, useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";

type TooltipProps = {
  children: ReactNode | ReactNode[];
  anchor: React.RefObject<HTMLElement>;
  wait?: number;
};

export function Tooltip({ anchor, children, wait = 300 }: TooltipProps) {
  const [coords, setCoords] = useState<[number, number] | null>(null);
  const [offsetX, setOffsetX] = useState<number | null>(null);
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const anchorEl = anchor.current;
    if (!anchorEl) {
      return () => {};
    }
    let timeout: NodeJS.Timeout;
    function handleMouseOver() {
      timeout = setTimeout(() => {
        if (anchorEl) {
          const rect = anchorEl.getBoundingClientRect();
          const top = rect.y + rect.height + 8;
          const left = rect.x + rect.width / 2;
          setCoords([left, top]);
        }
      }, wait);
    }
    function handleMouseOut() {
      setCoords(null);
      clearTimeout(timeout);
    }
    anchorEl.addEventListener("mouseenter", handleMouseOver);
    anchorEl.addEventListener("mouseleave", handleMouseOut);
    window.addEventListener("scroll", handleMouseOut);
    return () => {
      anchorEl.removeEventListener("mouseenter", handleMouseOver);
      anchorEl.removeEventListener("mouseleave", handleMouseOut);
      window.removeEventListener("scroll", handleMouseOut);
    };
  }, [anchor, wait]);

  useEffect(() => {
    const contentEl = contentRef.current;
    const observer = new ResizeObserver(() => {
      if (contentEl && coords) {
        const { width } = contentEl.getBoundingClientRect();
        const halfWidth = Math.floor(width / 2);
        const right = coords[0] + halfWidth;
        const left = coords[0] - halfWidth;
        let offsetX = 0;
        if (left < 0) {
          offsetX += -left + 4;
        } else if (right > document.body.clientWidth) {
          const delta = right - document.body.clientWidth;
          offsetX -= delta + 4;
        }
        setOffsetX(offsetX);
      }
    });
    if (contentEl) {
      observer.observe(contentEl);
    }
    return () => {
      if (contentEl) {
        observer.unobserve(contentEl);
      }
    };
  }, [coords]);

  if (coords) {
    return createPortal(
      <div
        className={css(styles.root, (coords == null || offsetX == null) && styles.hidden)}
        style={{
          top: coords[1],
          left: coords[0],
        }}
      >
        <img className={css(styles.caret)} src={require("./assets/tooltip-caret.png")} alt="" />
        <div className={css(styles.contents)} ref={contentRef} style={{ left: offsetX ?? 0 }}>
          {children}
        </div>
      </div>,
      document.body
    );
  }
  return null;
}

const styles = StyleSheet.create({
  root: {
    position: "fixed",
    pointerEvents: "none",
    userSelect: "none",
    width: 1000,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginLeft: -500,
    zIndex: 1000,
  },
  hidden: {
    opacity: 0,
  },
  caret: {
    zIndex: 10,
  },
  contents: {
    backgroundColor: "black",
    display: "flex",
    flexDirection: "row",
    padding: "4px 6px",
    gap: 8,
    position: "relative",
    zIndex: 5,
    marginTop: 0,
    // Jagged rounded corners
    boxShadow: "0px 1px black, 0px -1px black, 1px 0 black, -1px 0 black",
  },
});
