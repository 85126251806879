import { useState, useEffect } from "react";
import {
  unstable_scheduleCallback as scheduleCallback,
  unstable_cancelCallback as cancelCallback,
  unstable_LowPriority as LowPriority,
} from "scheduler";

/**
 * Memoizes the return value of the function at a low priority. It'll use the
 * last cache value while waiting for the latest to compute.
 *
 * It's recommended to add this function to the lint rule config for
 * `react-hooks/exhaustive-deps`'s `additionalHooks` param.
 */
export function useLowPriorityMemo<T>(fn: () => T, deps: any[]): T {
  const [cache, setCache] = useState<T>(fn);
  useEffect(() => {
    const cb = scheduleCallback(LowPriority, () => {
      setCache(fn());
    });
    return () => {
      cancelCallback(cb);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
  return cache;
}
