import spritesheet from "./assets/icons-12px-onblack.png";
import { StyleSheet, css } from "aphrodite/no-important";

const styles = StyleSheet.create({
  root: {
    display: "inline-flex",
    width: 12,
    height: 12,
    imageRendering: "pixelated",
    maskImage: `url("${spritesheet}")`,
  },
  flipX: {
    transform: "scaleX(-1)",
  },
  flipY: {
    transform: "scaleY(-1)",
  },
});

const iconStyles = StyleSheet.create({
  pencil: { maskPosition: "-2px -162px" },
  eraser: { maskPosition: "-18px -162px" },
  hand: { maskPosition: "-34px -162px" },
  dog: { maskPosition: "-50px -162px" },
  open: { maskPosition: "-2px -66px" },
  copy: { maskPosition: "-2px -34px" },
  paste: { maskPosition: "-18px -34px" },
  file: { maskPosition: "-34px -18px" },
  trash: { maskPosition: "-2px -18px" },
  download: { maskPosition: "-50px -2px" },
  undo: { maskPosition: "-2px -2px" },
  redo: { maskPosition: "-18px -2px" },
  save: { maskPosition: "-2px -178px" },
  move: { maskPosition: "-50px -146px" },
  marquee: { maskPosition: "-34px -130px" },
});

export type IconName = keyof typeof iconStyles;

export const ICON_NAMES: IconName[] = Object.keys(iconStyles) as any;

type IconProps = {
  name: IconName;
  color?: string;
  flipX?: boolean;
  flipY?: boolean;
  "aria-hidden"?: boolean;
};

export function Icon({ name, color = "#000", flipX, flipY, ...rest }: IconProps) {
  return (
    <div
      role="img"
      aria-label={name}
      aria-hidden={rest["aria-hidden"]}
      className={css(styles.root, iconStyles[name], flipX && styles.flipX, flipY && styles.flipY)}
      style={{ backgroundColor: color }}
    ></div>
  );
}
