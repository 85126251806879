import { useCallback, useEffect, useRef } from "react";

export function useDebounce<T extends Array<any>>(
  fn: (...args: T) => void,
  wait: number
): [(...args: T) => void, () => void] {
  const fnRef = useRef<(...args: T) => void>(fn);
  useEffect(() => {
    fnRef.current = fn;
  }, [fn]);

  const lastCallTimeout = useRef<NodeJS.Timeout | null>(null);

  return [
    useCallback(
      (...args: T) => {
        if (lastCallTimeout.current != null) {
          // There already exists a timeout, so clear it
          clearTimeout(lastCallTimeout.current);
          lastCallTimeout.current = null;
        }

        // Create a new timeout
        lastCallTimeout.current = setTimeout(() => {
          fnRef.current(...args);
          lastCallTimeout.current = null;
        }, wait);
      },
      [wait]
    ),
    useCallback((...args: T) => {
      if (lastCallTimeout.current != null) {
        clearTimeout(lastCallTimeout.current);
        lastCallTimeout.current = null;
      }
      fnRef.current(...args);
    }, []),
  ];
}

export function useThrottle<T extends Array<any>>(
  fn: (...args: T) => void,
  threshold: number
): [(...args: T) => void, () => void] {
  const fnRef = useRef<(...args: T) => void>(fn);
  useEffect(() => {
    fnRef.current = fn;
  }, [fn]);

  const lastCallTimeout = useRef<NodeJS.Timeout | null>(null);

  return [
    useCallback(
      (...args: T) => {
        // Create a new timeout if there hasn't been one already
        if (lastCallTimeout.current == null) {
          lastCallTimeout.current = setTimeout(() => {
            fnRef.current(...args);
            lastCallTimeout.current = null;
          }, threshold);
        }
      },
      [threshold]
    ),
    useCallback((...args: T) => {
      if (lastCallTimeout.current != null) {
        clearTimeout(lastCallTimeout.current);
        lastCallTimeout.current = null;
      }
      fnRef.current(...args);
    }, []),
  ];
}
