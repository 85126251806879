import { FontFile, Vec2 } from "./types";
import { getPixel } from "./utils/font";
import { StyleSheet, css } from "aphrodite/no-important";
import { memo, useEffect, useRef, useState } from "react";

type PxTextProps = {
  font: FontFile;
  children: string;
  scale?: number;
  maxWidth?: number;
  color?: string;
};

/**
 * Renders text in the provided font.
 * TODOS:
 * - custom advancewidth for each glyph
 * - resize the canvas if necessary
 * - line heights?
 * - context for font styles?
 */
function _PxText({ children = "", font, scale = 1, maxWidth = 512, color = "#000" }: PxTextProps) {
  const {
    glyphs,
    kerning,
    spacing: { canvasSize, marginLeft, letterSpacing, baseline, spaceSize, capline, descenderline },
  } = font;
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [textDim, setTextDim] = useState<Vec2>([0, canvasSize]);

  useEffect(() => {
    const canvasEl = canvasRef.current;
    if (!canvasEl) {
      return;
    }
    const ctx = canvasRef.current?.getContext("2d");
    if (!ctx) {
      return;
    }
    let canvasPos = 0;
    ctx.clearRect(0, 0, 512, canvasSize);
    ctx.fillStyle = color;
    for (let charIndex = 0; charIndex < children.length; charIndex++) {
      const charCode = children.charCodeAt(charIndex);
      if (children[charIndex]?.match(/\s/) && !children[charIndex - 1]?.match(/\s/)) {
        canvasPos += spaceSize + letterSpacing - 1;
        continue;
      }
      const glyph = glyphs[charCode];
      if (!glyph) continue;

      const kerningPair = kerning?.find((pair) => {
        return (
          pair.left === String(children.charCodeAt(charIndex - 1)) &&
          pair.right === String(charCode)
        );
      });
      if (kerningPair != null) {
        canvasPos += kerningPair.value;
      }

      let lastColumn = 0;
      for (let y = 0; y < canvasSize; y++) {
        for (let x = 0; x < canvasSize; x++) {
          const pixel = getPixel(glyph, x, y);
          if (pixel) {
            lastColumn = Math.max(x, lastColumn);
            ctx.fillRect(canvasPos + x, y, 1, 1);
          }
        }
      }
      // TODO: each glyph should be able to define its own advanceWidth
      canvasPos += lastColumn + 1 + letterSpacing - marginLeft;

      // TODO: resize the canvas?
    }
    setTextDim([canvasPos - letterSpacing, canvasSize]);
  }, [canvasSize, children, color, glyphs, letterSpacing, marginLeft, scale, spaceSize, kerning]);

  const ascenderSize = baseline - capline;
  const descenderSize = descenderline - baseline;
  const lineHeight = ascenderSize + descenderSize * 2;
  return (
    <div
      className={css(styles.root)}
      style={{
        width: textDim[0] * scale,
        height: lineHeight,
      }}
    >
      <canvas
        aria-hidden={true}
        ref={canvasRef}
        className={css(styles.canvas)}
        width={maxWidth}
        height={canvasSize}
        style={{
          width: maxWidth * scale,
          height: canvasSize * scale,
          top: -capline + descenderSize,
          left: -marginLeft,
        }}
      />
      <div className={css(styles.hidden)}>{children}</div>
    </div>
  );
}

export const PxText = memo(_PxText);

const styles = StyleSheet.create({
  root: {
    display: "inline-flex",
    verticalAlign: "baseline",
    position: "relative",
  },
  canvas: {
    imageRendering: "pixelated",
    position: "absolute",
    pointerEvents: "none",
  },
  hidden: {
    position: "fixed",
    left: -999999,
  },
});
