import { ReactNode, createContext, useContext, useEffect, useMemo, useState } from "react";

const CURSOR_MAPPING = {
  default: "cursor-default",
  move: "cursor-move",
} as const;

type CursorName = keyof typeof CURSOR_MAPPING;

type CursorContextType = Readonly<{
  cursor: CursorName;
  setCursor: (name: CursorName) => void;
  unsetCursor: () => void;
}>;

const DEFAULT_CURSOR_CONTEXT: CursorContextType = {
  cursor: "default",
  setCursor: () => {},
  unsetCursor: () => {},
};

const CursorContext = createContext(DEFAULT_CURSOR_CONTEXT);

export function CursorContextProvider({ children }: { children: ReactNode }) {
  const [cursor, setCursor] = useState<CursorName>("default");
  const contextValue = useMemo(() => {
    return {
      cursor,
      setCursor,
      unsetCursor: () => setCursor("default"),
    };
  }, [cursor]);

  useEffect(() => {
    const bodyClassName = CURSOR_MAPPING[cursor];
    const nonMatchingCursors = Object.entries(CURSOR_MAPPING)
      .filter(([name]) => name !== cursor)
      .map(([, className]) => className);
    const root = document.body;
    if (root) {
      root.classList.remove(...nonMatchingCursors);
      root.classList.add(bodyClassName);
    }
  }, [cursor]);

  return <CursorContext.Provider value={contextValue}>{children}</CursorContext.Provider>;
}

export function useCursorContext() {
  return useContext(CursorContext);
}
