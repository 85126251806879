/**
 * Utilities to edit glyphs and fonts
 */
import { CharCodeToGlyphData, FontFile, GlyphData, ReadonlyVec2 } from "../types";
import { EMPTY_FONT } from "./consts";
import produce from "immer";
import range from "lodash/range";
import {
  unstable_scheduleCallback as scheduleCallback,
  unstable_IdlePriority as IdlePriority,
} from "scheduler";

export function getPixel(data: GlyphData, x: number, y: number): boolean {
  if (data[y] == null) {
    return false;
  }
  return !!(data[y] & Math.pow(2, x));
}

function cleanupNulls(glyph: number[]): number[] {
  for (let i = 0; i < glyph.length; i++) {
    if (glyph[i] == null) {
      glyph[i] = 0;
    }
  }
  return glyph;
}

function lineLength(start: ReadonlyVec2, end: ReadonlyVec2): number {
  const x = end[0] - start[0];
  const y = end[1] - start[1];
  return Math.sqrt(x * x + y * y);
}

function lineAngle(start: ReadonlyVec2, end: ReadonlyVec2): number {
  const x = end[0] - start[0];
  const y = end[1] - start[1];
  return Math.atan(y / (x === 0 ? 0.01 : x)) + (x < 0 ? Math.PI : 0);
}

export function blank(size: number): GlyphData {
  return new Array(size).fill(0);
}

export function setLine(
  glyph: GlyphData,
  start: ReadonlyVec2,
  end: ReadonlyVec2,
  newValue: boolean
): GlyphData {
  const newData: number[] = [...glyph];
  const len = lineLength(start, end);
  const angle = lineAngle(start, end);
  for (let i = 0; i <= len; i++) {
    const x = start[0] + Math.round(Math.cos(angle) * i);
    const y = start[1] + Math.round(Math.sin(angle) * i);
    if (newValue === true) {
      newData[y] |= Math.pow(2, x);
    } else {
      newData[y] &= ~Math.pow(2, x);
    }
  }
  return cleanupNulls(newData);
}

export function setRect(
  glyph: GlyphData,
  start: ReadonlyVec2,
  end: ReadonlyVec2,
  newValue: boolean
) {
  const newData: number[] = [...glyph];
  for (const x of [...range(start[0], end[0]), end[0]]) {
    for (const y of [...range(start[1], end[1]), end[1]]) {
      if (newValue === true) {
        newData[y] |= Math.pow(2, x);
      } else {
        newData[y] &= ~Math.pow(2, x);
      }
    }
  }
  return cleanupNulls(newData);
}

export function panGlyph(
  glyph: GlyphData,
  [deltaX, deltaY]: ReadonlyVec2,
  canvasSize: number
): GlyphData {
  const newGlyph: number[] = Array(canvasSize).fill(0);
  for (let rowNum = 0; rowNum < glyph.length; rowNum++) {
    const rowToUse = (canvasSize + rowNum - deltaY) % canvasSize;
    const rowMask = Math.pow(2, canvasSize) - 1;
    const oldGlyphRow = (glyph[rowToUse] ?? 0) & rowMask;
    if (deltaX > 0) {
      newGlyph[rowNum] =
        ((oldGlyphRow << deltaX) | (oldGlyphRow >>> (canvasSize - deltaX))) %
        Math.pow(2, canvasSize);
    } else if (deltaX < 0) {
      newGlyph[rowNum] =
        ((oldGlyphRow >>> -deltaX) | (oldGlyphRow << (canvasSize + deltaX))) %
        Math.pow(2, canvasSize);
    } else {
      newGlyph[rowNum] = oldGlyphRow;
    }
  }
  return newGlyph;
}

export function isGlyphEmpty(data: GlyphData): boolean {
  return data.reduce((prev, cur) => prev + cur, 0) === 0;
}

type Bounds = {
  xMin: number;
  xMax: number;
  yMin: number;
  yMax: number;
};

export function getGlyphBounds(data: GlyphData, canvasSize: number): Bounds {
  let xMin = Infinity;
  let xMax = -Infinity;
  let yMin = Infinity;
  let yMax = -Infinity;
  if (isGlyphEmpty(data)) {
    // Empty glyph
    return { xMin: 0, xMax: 0, yMin: 0, yMax: 0 };
  }
  for (let y = 0; y < canvasSize; y++) {
    for (let x = 0; x < canvasSize; x++) {
      if (getPixel(data, x, y)) {
        xMin = Math.min(xMin, x);
        xMax = Math.max(xMax, x);
        yMin = Math.min(yMin, y);
        yMax = Math.max(yMax, y);
      }
    }
  }
  return { xMin, xMax, yMin, yMax };
}

export function getFontBounds(glyphs: GlyphData[], canvasSize: number): Bounds {
  return glyphs.reduce(
    (acc, current) => {
      const bounds = getGlyphBounds(current, canvasSize);
      return {
        xMin: Math.min(acc.xMin, bounds.xMin),
        xMax: Math.max(acc.xMax, bounds.xMax),
        yMin: Math.min(acc.yMin, bounds.yMin),
        yMax: Math.max(acc.yMax, bounds.yMax),
      };
    },
    {
      xMin: canvasSize,
      xMax: 0,
      yMin: canvasSize,
      yMax: 0,
    }
  );
}

export function filterNonGlyphData(map: { [key: string]: any }): CharCodeToGlyphData {
  const ret: CharCodeToGlyphData = {};
  for (const [key, value] of Object.entries(map)) {
    if (isFinite(+key) && +key >= 0 && +key < 256) {
      ret[key] = value;
    }
  }
  return ret;
}

export function convertFromBitFontMaker2(data: any): FontFile {
  return produce(EMPTY_FONT, (draft) => {
    const glyphs: any = filterNonGlyphData(data);
    draft.glyphs = glyphs;
    const { copy, name, wordspacing } = data;
    if (typeof copy === "string") {
      draft.meta.author = copy;
    }
    if (typeof name === "string") {
      const styleRegex = /(bold|regular|italic|light|medium|bolditalic|lightitalic)/i;
      const matches = name.match(styleRegex);
      if (matches && typeof matches[0] === "string") {
        draft.meta.style = matches[0];
        draft.meta.name = name.replace(matches[0], "").trim();
      } else {
        draft.meta.name = name;
      }
    }
    if (typeof wordspacing === "string") {
      draft.spacing.spaceSize = +data.wordspacing;
    }
    return draft;
  });
}

export async function downloadOTF(fontFile: FontFile) {
  const { internal_downloadOTF } = await import("./downloadOTF");
  internal_downloadOTF(fontFile);
}

export async function downloadJSON(fontFile: FontFile) {
  const { internal_downloadJSON } = await import("./downloadJSON");
  internal_downloadJSON(fontFile);
}

export async function preloadDownloaders(): Promise<void> {
  await new Promise<void>((resolve) => scheduleCallback(IdlePriority, resolve));
  await Promise.all([import("./downloadOTF"), import("./downloadJSON")]);
}
