import { StyleSheet, css } from "aphrodite/no-important";
import React, { ReactNode, useMemo, useState } from "react";
import tinycolor from "tinycolor2";

type BaseButtonProps = {
  children: ReactNode[] | ReactNode;
  color: string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onMouseDown?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onMouseUp?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onMouseOver?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onMouseOut?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  hoverVector?: [number, number];
  ref?: React.RefObject<HTMLButtonElement>;
};

export const BaseButton = React.forwardRef<HTMLButtonElement, BaseButtonProps>(
  (
    {
      children,
      color,
      onClick,
      onMouseDown,
      onMouseUp,
      onMouseOver,
      onMouseOut,
      hoverVector = [0, -3],
    },
    ref
  ) => {
    const [isHovering, setIsHovering] = useState<boolean>(false);
    const [isClicking, setIsClicking] = useState<boolean>(false);
    const [bright, dark] = useMemo(() => {
      const c = tinycolor(color);
      const isBright = c.getBrightness() > 0.5;
      const b = isBright ? c : c.clone().brighten(15);
      let d = b.clone().darken(8);
      if (Math.abs(c.getBrightness() - d.getBrightness()) < 0.05) {
        d = c.clone().brighten(5);
      }
      return [b.toHexString(), d.toHexString()];
    }, [color]);

    const contentsStyle: React.CSSProperties = { backgroundColor: color };
    if (isHovering) {
      contentsStyle.backgroundColor = bright;
    }
    if (isHovering && !isClicking) {
      contentsStyle.transform = `translate(${hoverVector[0]}px, ${hoverVector[1]}px)`;
    }

    return (
      <button
        ref={ref}
        className={css(baseButtonStyle.root)}
        onClick={onClick}
        onMouseDown={(event) => {
          setIsClicking(true);
          onMouseDown?.(event);
        }}
        onMouseOut={(event) => {
          setIsHovering(false);
          setIsClicking(false);
          onMouseOut?.(event);
        }}
        onMouseOver={(event) => {
          setIsHovering(true);
          onMouseOver?.(event);
        }}
        onMouseUp={(event) => {
          setIsClicking(false);
          onMouseUp?.(event);
        }}
        style={{ backgroundColor: color }}
      >
        <div className={css(baseButtonStyle.contents)} style={contentsStyle}>
          {children}
        </div>
        <div
          className={css(baseButtonStyle.under)}
          style={isHovering ? { backgroundColor: dark } : undefined}
        />
      </button>
    );
  }
);

const baseButtonStyle = StyleSheet.create({
  root: {
    border: "none",
    padding: 0,
    margin: 0,
    position: "relative",
    display: "flex",
    alignItems: "stretch",
  },
  under: {
    zIndex: 1,
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
  },
  contents: {
    zIndex: 10,
    transition: "transform 0.1s ease-out",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    pointerEvents: "none",
    flex: 1,
  },
});

type ButtonProps = {
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  children: React.ReactNode | React.ReactNode[];
  color?: string;
};

export function Button({ onClick, children, color = "red" }: ButtonProps) {
  return (
    <BaseButton color={color} onClick={onClick}>
      <div className={css(buttonStyle.contents)}>{children}</div>
    </BaseButton>
  );
}

const buttonStyle = StyleSheet.create({
  contents: {
    zIndex: 10,
    padding: "4px 8px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    lineHeight: "24px",
    height: 24,
    gap: 4,
  },
});
