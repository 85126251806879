import { CharCode, CharCodeToGlyphData, FontFile } from "../types";

export const PXF_VERSION = "1.0.0";

export const GLYPH_SORT_ORDER: CharCode[] = [
  "65", // capital A
  "66", // capital B
  "67", // capital C
  "68", // capital D
  "69", // capital E
  "70", // capital F
  "71", // capital G
  "72", // capital H
  "73", // capital I
  "74", // capital J
  "75", // capital K
  "76", // capital L
  "77", // capital M
  "78", // capital N
  "79", // capital O
  "80", // capital P
  "81", // capital Q
  "82", // capital R
  "83", // capital S
  "84", // capital T
  "85", // capital U
  "86", // capital V
  "87", // capital W
  "88", // capital X
  "89", // capital Y
  "90", // capital Z

  "97", // small a
  "98", // small b
  "99", // small c
  "100", // small d
  "101", // small e
  "102", // small f
  "103", // small g
  "104", // small h
  "105", // small i
  "106", // small j
  "107", // small k
  "108", // small l
  "109", // small m
  "110", // small n
  "111", // small o
  "112", // small p
  "113", // small q
  "114", // small r
  "115", // small s
  "116", // small t
  "117", // small u
  "118", // small v
  "119", // small w
  "120", // small x
  "121", // small y
  "122", // small z

  "48", // digit 0
  "49", // digit 1
  "50", // digit 2
  "51", // digit 3
  "52", // digit 4
  "53", // digit 5
  "54", // digit 6
  "55", // digit 7
  "56", // digit 8
  "57", // digit 9

  "33",
  "34",
  "35",
  "36",
  "37",
  "38",
  "39",
  "40",
  "41",
  "42",
  "43",
  "44",
  "45",
  "46",
  "47",

  "58",
  "59",
  "60",
  "61",
  "62",
  "63",
  "64",

  "91",
  "92", // \
  "93",
  "94",
  "95",
  "96",

  "123",
  "124",
  "125",
  "126",
];

export const EMPTY_FONT: FontFile = {
  glyphs: GLYPH_SORT_ORDER.reduce((acc, value) => {
    acc[value] = Array(16).fill(0);
    return acc;
  }, {} as CharCodeToGlyphData),
  spacing: {
    canvasSize: 16,
    baseline: 12,
    capline: 2,
    descenderline: 16,
    marginLeft: 4,
    spaceSize: 4,
    letterSpacing: 1,
  },
  meta: {
    name: "New Font",
    style: "Regular",
    author: "",
  },
  kerning: [],
};
