enum Status {
  Pending,
  Success,
  Error,
}
export function wrapPromise<T>(promise: Promise<T>): { read: () => T } {
  let status: Status = Status.Pending;
  let result: T;
  let suspender = promise.then(
    (r) => {
      status = Status.Success;
      result = r;
    },
    (e) => {
      status = Status.Error;
      result = e;
    }
  );
  return {
    read(): T {
      switch (status) {
        case Status.Pending:
          throw suspender;
        case Status.Error:
          throw result;
        case Status.Success:
          return result;
      }
    },
  };
}
