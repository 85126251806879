import { useActiveFontSelector } from "./FontContext";
import { GlyphCanvas } from "./GlyphCanvas";
import { PxText } from "./PxText";
import { Tooltip } from "./Tooltip";
import { SIXER_REGULAR } from "./UIFonts";
import { CharCode } from "./types";
import { GLYPH_SORT_ORDER } from "./utils/consts";
import { useLowPriorityMemo } from "./utils/usePrioritizedMemo";
import { StyleSheet, css } from "aphrodite/no-important";
import uniq from "lodash/uniq";
import { memo, useState, useCallback, useRef } from "react";

type GlyphGalleryProps = {
  currentCharCode?: CharCode;
  onGlyphClick?: (charCode: CharCode) => void;
};

function _GlyphGallery({ onGlyphClick, currentCharCode }: GlyphGalleryProps) {
  // TODO: is there a better way to stabilize the value here?
  //       maybe cache based on the count of glyphs and always run the
  //       low-priority recomputation
  const glyphKeysString = useActiveFontSelector((f) => Object.keys(f.glyphs).join("||"));
  const glyphOrder = useLowPriorityMemo(() => {
    const sorted = glyphKeysString.split("||").sort((a, b) => Number(a) - Number(b));
    return uniq([...GLYPH_SORT_ORDER, ...sorted]);
  }, [glyphKeysString]);
  return (
    <div className={css(gstyles.root)}>
      <div className={css(gstyles.glyphList)}>
        {glyphOrder.map((charCode) => {
          return (
            <div key={charCode} className={css(gstyles.glyphCell)}>
              <GlyphCell
                charCode={charCode}
                onClick={onGlyphClick}
                isActive={charCode === currentCharCode}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
}
export const GlyphGallery = memo(_GlyphGallery);

const CELL_WIDTH = 32;

const gstyles = StyleSheet.create({
  root: {},
  glyphList: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    width: CELL_WIDTH * 13,
    paddingLeft: 1,
    paddingTop: 1,
  },
  glyphCell: {
    marginLeft: -1,
    marginTop: -1,
    marginBottom: 5,
  },
});

type GlyphCellProps = {
  charCode: CharCode;
  onClick?: (charCode: CharCode) => void;
  isActive?: boolean;
};

function GlyphCell({ charCode, onClick, isActive = false }: GlyphCellProps) {
  const glyph = useActiveFontSelector((f) => f.glyphs[charCode]);
  const [isHovering, setIsHovering] = useState<boolean>(false);
  const [isClicking, setIsClicking] = useState<boolean>(false);
  const ref = useRef<HTMLDivElement>(null);
  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      if (onClick) {
        event.preventDefault();
        onClick(charCode);
      }
    },
    [charCode, onClick]
  );
  const spacing = useActiveFontSelector((f) => f.spacing);
  if (!glyph) {
    return null;
  }
  return (
    <>
      <div
        ref={ref}
        className={css(cstyles.root)}
        onClick={handleClick}
        onMouseOver={() => setIsHovering(true)}
        onMouseOut={() => {
          setIsHovering(false);
          setIsClicking(false);
        }}
        onMouseDown={() => setIsClicking(true)}
        onMouseUp={() => setIsClicking(false)}
      >
        <div
          className={css(
            cstyles.inner,
            isHovering && cstyles.innerHover,
            isClicking && cstyles.innerClicking
          )}
        >
          <div className={css(cstyles.title, isActive && cstyles.activeTitle)}>
            <PxText color="white" font={SIXER_REGULAR}>
              {String.fromCharCode(+charCode)}
            </PxText>
          </div>
          <div className={css(cstyles.canvas)}>
            <GlyphCanvas glyph={glyph} scale={1} spacing={spacing} />
          </div>
        </div>
      </div>
      <Tooltip anchor={ref}>
        <PxText color="white" font={SIXER_REGULAR}>
          {`${charCode} (0x${(+charCode).toString(16).toUpperCase()})`}
        </PxText>
      </Tooltip>
    </>
  );
}

const cstyles = StyleSheet.create({
  root: {
    display: "flex",
    width: CELL_WIDTH,
    flexDirection: "column",
    userSelect: "none",
    backgroundColor: "black",
    alignItems: "stretch",
  },
  inner: {
    border: "1px solid black",
    pointerEvents: "none",
    height: 32,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: "white",
    transition: "transform 0.08s ease-out",
  },
  innerHover: {
    transform: "translateY(-3px)",
  },
  innerClicking: {
    transform: "none",
  },
  title: {
    backgroundColor: "black",
    color: "white",
    display: "flex",
    alignSelf: "stretch",
    alignItems: "center",
    justifyContent: "center",
    height: 12,
    borderBottom: "1px solid black",
    flexShrink: 0,
  },
  activeTitle: {
    backgroundColor: "red",
    borderColor: "red",
  },
  canvas: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "white",
    margin: "1px 0 0 1px",
  },
});
