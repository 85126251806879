import App from "./App";
import { CursorContextProvider } from "./CursorContext";
import { FontContextProvider } from "./FontContext";
import { ToolbarContextProvider } from "./Toolbar";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { enablePatches } from "immer";
import React from "react";
import ReactDOM from "react-dom/client";

enablePatches();

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <React.StrictMode>
    <React.Suspense fallback={<div>loading...</div>}>
      <FontContextProvider>
        <CursorContextProvider>
          <ToolbarContextProvider>
            <App />
          </ToolbarContextProvider>
        </CursorContextProvider>
      </FontContextProvider>
    </React.Suspense>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
